import ConditionalSkeleton from '@/components/animations/conditionalSkeleton';
import CustomCheckBox from '@/components/customCheckBox';
import TextFieldInputLabel from '@/components/form/inputLabel';
import SearchBar from '@/components/searchBar';
import ClientListItemSkeleton from '@/components/skeletons/clientListItemSkeleton';
import { useInfiniteGraphQL } from '@/data';
import { queryGraphQL } from '@/data/apollo';
import { HouseAccountRead } from '@/graphql/houseAccount.graphql';

import {
	Avatar,
	Box,
	Button,
	Collapse,
	FormControlLabel,
	FormHelperText,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Paper,
	Stack,
	Switch,
	Tooltip,
	Typography,
	Zoom,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { addDays, format, startOfMonth } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { flatten, isEmpty, isEqual, set, toUpper, unionBy } from 'lodash-es';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedValue } from 'rooks';
import currencyFormat from '../../../../../helpers/currencyFormat';
import useInfiniteQuery from '../../../../../hooks/useInfiniteQuery';
import { HouseAccount, Item, QueryHouseAccountReadArgs } from '../../../../../types/schema';
import { getBrowserTimezone } from '../../../../../utils/timezone';
import { StatementHouseAccounts } from './HAInvoicesGQL';

const HOUSE_ACCOUNTS_LIMIT = 200;
export const ellipsisSX = {
	maxWidth       : 200,
	whiteSpace     : 'nowrap',
	overflow       : 'hidden',
	textOverflow   : 'ellipsis',
	WebkitLineClamp: 1,
};

export default function SelectLeftGrid( {
	month,
	selectedHouseAccount,
	selectedHouseAccounts,
	setMonth,
	setSelectedHouseAccounts,
	setSelectedHouseAccount,
	setIncludePaid,
}: {
	month: string,
	selectedHouseAccount: HouseAccount,
	selectedHouseAccounts: HouseAccount[],
	setCheckedClients?: ( allClients: boolean ) => void,
	setMonth: Dispatch<SetStateAction<string>>,
	setSelectedHouseAccounts: Dispatch<SetStateAction<HouseAccount[]>>,
	setSelectedHouseAccount: Dispatch<SetStateAction<HouseAccount | null>>,
	setIncludePaid: Dispatch<SetStateAction<boolean>>
} ) {
	const timezone = getBrowserTimezone();
	const [ search, setSearch ] = useState( '' );
	const [ hasBalance, setHasBalance ] = useState( false );
	const [ debouncedHasBalance, _ ] = useDebouncedValue( hasBalance, 800 );
	const [ selectMsg, setSelectMsg ] = useState( '' );
	const [ checkBoxMinusIcon, setCheckBoxMinusIcon ] = useState( false );
	const [ tempHouseAccountData, setTempHouseAccountData ] = useState<HouseAccount[]>( [] );
	const [ delayedSearch ] = useDebouncedValue( search, 500 );
	const [ value, setValue ] = useState<Date | null>( null );
	
	const selectedMonth = month !== 'AllMonths' ? utcToZonedTime( new Date( month ), timezone ) : null;
	const prevHouseAccountDataRef = useRef<HouseAccount[] | undefined>( undefined );
	
	const setMonthValue = ( newValue: Date | null, allMonths: boolean ) => {
		setSelectedHouseAccounts( [] );
		setSelectedHouseAccount( null );
		setIncludePaid( false );
		setValue( allMonths ? null : newValue );
		const month = !newValue ? '' : format( addDays( startOfMonth( newValue ), 1 ), 'MM-dd-yyyy' );
		setMonth( allMonths ? 'AllMonths' : month );
	};
	
	const {
		data: houseAccountsNewData,
		isFetched,
		isFetching,
		hasNextPage,
		fetchNextPage,
	}
		      = useInfiniteGraphQL<
		      	{ options: { limit: number; filter: any; } },
		      	{ id: string; items: Item[] }>( {
		      	query    : StatementHouseAccounts,
		      	queryKey : [ 'houseAccountReads' ],
		      	variables: {
		      		options: {
		      			limit : HOUSE_ACCOUNTS_LIMIT,
		      			filter: {
		      				active      : true,
		      				balance     : debouncedHasBalance ? { $gt: 0 } : undefined,
		      				invoiceDates: month === 'AllMonths'
		      					? undefined : selectedMonth ? { $overlap: [ format( selectedMonth, 'MMMM yyyy' ) ] } : undefined,
		      				...delayedSearch?.length && {
		      					$or: [ 'name', 'contact', 'email' ].map( ( field ) => set( {}, field, { $ilike: delayedSearch } ) ),
		      				},
		      			},
		      		},
		      	},
		      }, { enabled: Boolean( month ) } );
	
	const lastElementRef = useInfiniteQuery( fetchNextPage, hasNextPage, isFetching );
	
	const houseAccountsCurrentData = useMemo( () => {
		if ( !houseAccountsNewData?.pages ) return [];
		return unionBy( flatten( houseAccountsNewData?.pages.map( ( { data } ) =>
			data.houseAccountsRead.items ) ), 'id' );
		
	}, [ houseAccountsNewData ] );
	
	useEffect( () => {
		if ( !isEqual( prevHouseAccountDataRef.current, houseAccountsCurrentData ) ) {
			setTempHouseAccountData( houseAccountsCurrentData );
			prevHouseAccountDataRef.current = houseAccountsCurrentData;
		}
	}, [ houseAccountsCurrentData, setTempHouseAccountData ] );
	
	const selectedAllLength = tempHouseAccountData?.length && tempHouseAccountData?.length < HOUSE_ACCOUNTS_LIMIT
		? tempHouseAccountData?.length : HOUSE_ACCOUNTS_LIMIT;
	
	const allChecked = selectedAllLength === selectedHouseAccounts?.length;
	const handleSelectIndividualHouseAccount = async ( e, houseAccount: HouseAccount, hasCheckedHouseAccount ) => {
		e.stopPropagation();
		//unselect House accounts
		if ( hasCheckedHouseAccount ) {
			if ( allChecked ) {
				setCheckBoxMinusIcon( true );
			}
			const remainingHouseAccounts = selectedHouseAccounts.filter( ( ha ) => ha.id !== houseAccount.id );
			
			if ( selectedHouseAccounts.length === 2 ) {
				const { houseAccountRead } = await queryGraphQL<QueryHouseAccountReadArgs>( {
					query    : HouseAccountRead,
					variables: { id: remainingHouseAccounts[ 0 ].id },
				} );
				setSelectedHouseAccount( houseAccountRead );
				setIncludePaid( Boolean( houseAccountRead.metadata?.includePaidInStatement ) );
				setSelectedHouseAccounts( [ houseAccountRead ] );
				
			} else {
				setSelectedHouseAccounts( remainingHouseAccounts );
			}
			if ( remainingHouseAccounts.length < 101 ) {
				setSelectMsg( '' );
			}
		} else {
			// select house accounts
			if ( selectedHouseAccounts.length === HOUSE_ACCOUNTS_LIMIT ) {
				setSelectMsg( `Upto ${HOUSE_ACCOUNTS_LIMIT} House Accounts are allowed to be selected.` );
				return;
			}
			
			if ( isEmpty( selectedHouseAccounts ) && houseAccount.id ) {
				const { houseAccountRead } = await queryGraphQL<QueryHouseAccountReadArgs>( {
					query    : HouseAccountRead,
					variables: { id: houseAccount.id },
				} );
				setSelectedHouseAccounts( [ houseAccountRead ] );
			} else {
				const allSelectedHouseAccounts = [
					...selectedHouseAccounts,
					houseAccount,
				];
				if ( allSelectedHouseAccounts.length === selectedAllLength ) {
					setCheckBoxMinusIcon( false );
				}
				setSelectedHouseAccounts( allSelectedHouseAccounts );
			}
		}
	};
	
	return (
		<Paper
			variant='borderless'
			sx={{
				overflow: 'hidden',
				position: 'sticky',
				p       : 2,
				top     : 70,
			}}>
			<Stack>
				<TextFieldInputLabel label='Select Month'/>
				<DateTimePicker<Date>
					value={value}
					views={[ 'year', 'month' ]}
					minDate={new Date( '2023-08-01' )}
					maxDate={new Date()}
					ampm={false}
					closeOnSelect={false}
					onChange={( newValue ) => setMonthValue( newValue, false )}
				/>
			</Stack>
			<Stack>
				<Typography textAlign='center'>OR</Typography>
				<Button
					variant={month === 'AllMonths' ? 'contained' : 'outlined'}
					onClick={() => setMonthValue( null, true )}>
					All Months
				</Button>
			</Stack>
			<Collapse in={!isEmpty( houseAccountsCurrentData ) || !isEmpty( tempHouseAccountData )}>
				<Tooltip
					placement='right'
					title='Only display the house account with balances'>
					<FormControlLabel
						checked={hasBalance}
						control={<Switch/>}
						sx={{ my: 2 }}
						label='Balance only'
						onChange={( e, checked: boolean ) => {
							setHasBalance( checked );
							if ( checked ) {
								if ( ( selectedHouseAccount?.balance || 0 ) < 1 ) {
									setSelectedHouseAccount( null );
									setIncludePaid( false );
								}
								setSelectedHouseAccounts( selectedHouseAccounts );
							}
							setTempHouseAccountData( houseAccountsCurrentData );
							
						}}
					/>
				</Tooltip>
			</Collapse>
			{selectMsg && (
				<FormHelperText color='success'>
					{selectMsg}
				</FormHelperText>
			)}
			{month && (
				<Box>
					{!isEmpty( houseAccountsCurrentData ) && (
						<Stack direction='row' spacing={1} alignItems='center'>
							<Typography>Total: {tempHouseAccountData?.length || 0}</Typography>
							{!isEmpty( tempHouseAccountData ) && (
								<Typography>
									| Selected: {selectedHouseAccounts?.length}
								</Typography>
							)}
						</Stack>
					)}
					<Stack direction='row' spacing={1} alignItems='center'>
						{!isEmpty( tempHouseAccountData ) && (
							<Zoom in>
								<div>
									<CustomCheckBox
										checked={checkBoxMinusIcon ? false : allChecked}
										indeterminate={checkBoxMinusIcon}
										onClick={() => {
											if ( checkBoxMinusIcon || !allChecked ) {
												setSelectedHouseAccounts( tempHouseAccountData?.slice( 0, HOUSE_ACCOUNTS_LIMIT ) );
											} else {
												setSelectedHouseAccounts( [] );
											}
											setCheckBoxMinusIcon( false );
										}}
									/>
								</div>
							</Zoom>
						)}
						<SearchBar
							fullWidth
							size='medium'
							placeholder='Search to see more house accounts'
							setSearch={setSearch}
							sx={{ py: 2 }}
						/>
					</Stack>
					<ConditionalSkeleton
						isLoading={isFetching && !isFetched}
						skeleton={( <ClientListItemSkeleton length={10}/> )}>
						{!isEmpty( tempHouseAccountData ) ? (
							<Stack
								sx={{ maxHeight: 600, overflow: 'overlay' }}
								spacing={1}>
								{tempHouseAccountData.map( ( houseAccount, index ) => {
									const hasCheckedHouseAccount = selectedHouseAccounts.find( ( ha ) => ha?.id === houseAccount.id );
									const isLast = tempHouseAccountData.length === index + 1;
									
									return (
										<Box
											key={index}
											ref={isLast ? lastElementRef : undefined}>
											<ListItemButton
												disableGutters
												selected={selectedHouseAccount?.id === houseAccount.id}
												onClick={async () => {
													if ( selectedHouseAccount?.id === houseAccount.id ) {
														setSelectedHouseAccount( null );
														setIncludePaid( false );
													} else {
														const { houseAccountRead } = await queryGraphQL<QueryHouseAccountReadArgs>( {
															query    : HouseAccountRead,
															variables: { id: houseAccount.id },
														} );
														
														setSelectedHouseAccount( houseAccountRead );
														setIncludePaid( Boolean( houseAccountRead.metadata?.includePaidInStatement ) );
													}
												}}>
												<ListItemAvatar>
													<Stack direction='row' spacing={1} pr={1}>
														<Zoom in>
															<div>
																<CustomCheckBox
																	checked={Boolean( hasCheckedHouseAccount )}
																	onClick={async ( e ) => await handleSelectIndividualHouseAccount(
																		e,
																		houseAccount,
																		hasCheckedHouseAccount,
																	)}
																/>
															</div>
														</Zoom>
														<Avatar src={houseAccount.logo || ''}>
															{toUpper( houseAccount.name?.[ 0 ] ) || toUpper( houseAccount.contact?.[ 0 ] ) || toUpper( houseAccount?.client?.name?.[ 0 ] ) || toUpper( houseAccount?.client?.contact?.[ 0 ] ) || ''}
														</Avatar>
													</Stack>
												</ListItemAvatar>
												<ListItemText
													primary={houseAccount?.name || houseAccount?.contact || houseAccount?.client?.name || houseAccount?.client?.contact || '-'}
													secondary={houseAccount?.email || '-'}
													primaryTypographyProps={{ sx: ellipsisSX }}
													secondaryTypographyProps={{ sx: ellipsisSX }}
												/>
												<Tooltip title='Entire balance of the account'>
													<Typography sx={{ pr: 1 }}>
														{currencyFormat( houseAccount.balance )}
													</Typography>
												</Tooltip>
											</ListItemButton>
										</Box>
									);
								} )}
							</Stack>
						) : (
							<Typography sx={{ textAlign: 'center', p: 2 }}>
								No House Accounts to display
							</Typography>
						)}
					</ConditionalSkeleton>
				</Box>
			)}
		</Paper>
	);
}

