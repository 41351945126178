import { HapticType, sendReactNativeNotification } from '@/helpers/reactNativePostMessage';
import { CircularProgress, colors, Fade, Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';

type AsyncLoadingSwitchProps = {
	onChange?: ( event: ChangeEvent<HTMLInputElement>, checked: boolean ) => Promise<void>,
	hapticType?: HapticType
	loading?: boolean,
};

const Circle = styled( 'div' )<{ checked: boolean, disabled: boolean }>( ( { theme, checked, disabled } ) => ( {
	display        : 'flex',
	alignItems     : 'center',
	justifyContent : 'center',
	width          : 20,
	height         : 20,
	borderRadius   : '50%',
	backgroundColor: disabled
		? checked
			? theme.palette.mode === 'dark' ? 'rgb(14, 67, 109)' : 'rgb(195, 192, 255)'
			: theme.palette.mode === 'dark' ? colors.grey[ 600 ] : colors.grey[ 100 ]
		: checked
			? theme.palette.primary.main
			: theme.palette.mode === 'dark' ? colors.grey[ 300 ] : '#ffffff',
	boxShadow: Array.isArray( theme.shadows ) ? theme.shadows[ 1 ] : undefined,
} ) );

function LoadingIcon( { loading, checked, disabled }: { loading: boolean, checked: boolean, disabled: boolean } ) {
	return (
		<Circle checked={checked} disabled={disabled}>
			{loading && (
				<Fade in>
					<CircularProgress size={12} sx={{ color: 'text.secondary' }} thickness={4}/>
				</Fade>
			)}
		</Circle>
	);
}

export default function AsyncLoadingSwitch( {
	onChange,
	hapticType = 'light',
	loading: isLoading = false,
	...props
}: AsyncLoadingSwitchProps & Omit<SwitchProps, 'onChange'> ) {
	const { enqueueSnackbar } = useSnackbar();
	const [ internalLoading, setInternalLoading ] = useState( false );
	const [ checked, setChecked ] = useState( props.checked || false );

	const loading = isLoading || internalLoading;

	const handleChange = async ( event: ChangeEvent<HTMLInputElement>, checked: boolean ) => {
		setChecked( checked );
		try {
			sendReactNativeNotification( { type: 'haptic', value: hapticType } );
			setInternalLoading( true );
			await onChange?.( event, checked );
		} catch ( e ) {
			enqueueSnackbar(
				String(
					e?.response?.data?.error?.message
					|| e?.response?.data
					|| e?.message
					|| e,
				),
				{ variant: 'error' },
			);
		} finally {
			setInternalLoading( false );
		}
	};
	
	return (
		<Switch
			size='medium'
			checkedIcon={<LoadingIcon loading={loading} checked={checked} disabled={loading || props.disabled || false}/>}
			icon={<LoadingIcon loading={loading} checked={checked} disabled={loading || props.disabled || false}/>}
			checked={checked}
			disabled={loading}
			onChange={handleChange}
			{...props}
		/>
	);
}
