import { EstimatesRead } from '@/data/commerce/estimate.graphql';
import MobileRowPanel from '@/pages/dashboard/commerce/components/mobileRow';
import useEstimateActions from '@/pages/dashboard/commerce/estimates/actions/tableActions';
import RowPanel from '@/pages/dashboard/commerce/estimates/rowPanel';
import { columns } from '@/pages/dashboard/commerce/estimates/table';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Order, QueryEstimatesReadArgs } from '@/types/schema';
import GraphqlTable from '../graphqlTable';

export default function Estimates( { variables } ) {
	const { staff } = useUserInfo();
	
	if ( !variables?.filter?.clientId && !variables?.filter?.estimatesStatuses ) return null;
	
	return (
		<GraphqlTable<Order, QueryEstimatesReadArgs>
			showFooter
			disableUrlSync
			returnNullOnEmpty
			searchable
			queryKey='estimates'
			query={EstimatesRead}
			columns={columns}
			variables={( {
				options: {
					filter: {
						client: variables.filter.clientId,
						status: variables.filter.estimatesStatuses,
					},
				},
			} )}
			subscription={{ ESTIMATE: staff?.company.id ?? '' }}
			initialState={{
				hiddenColumns: [
					'id',
					'taxTotal',
					'updatedAt',
					'client.id',
					'metadata.signature',
					'payments',
					'houseAccount.id',
					'houseAccount',
					'management:location',
					'type',
					'po',
				],
			}}
			expandedComponent={( row ) => <RowPanel row={row}/>}
			useActions={useEstimateActions}
			mobileRenderRow={( estimate ) => <MobileRowPanel data={estimate as any}/>}
		/>
	);
}
