import { useGraphQL } from '@/data';
import { CommercesStatement } from '@/data/commerce/commerce.graphql';
import MobileRowPanel from '@/pages/dashboard/commerce/components/mobileRow';
import RowPanel from '@/pages/dashboard/commerce/invoices/rowPanel';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { Client, Order, QueryCommercesStatementArgs } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Fade, Grid, IconButton, Paper } from '@mui/material';
import { format, startOfMonth, startOfYear } from 'date-fns';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from 'rooks';
import GraphqlTable from '../graphqlTable';
import CompanyMetricEmptyPage from '../page/empty';
import PageWrapper from '../page/wrapper';
import OpenInvoicesDialogActions from './actions';
import ClientStatementContent from './content';
import useOpenStatementsDatesMemo from './dates';
import MultipleClientStatements from './multipleClientStatements';
import SelectClient from './selectClient';
import UrlFields from './urlFields';
import { statementColumns } from './yearlyMonthly';

const statuses = {
	invoices : [ 'SENT', 'VIEWED', 'PARTIALLY_PAID' ],
	orders   : [ 'SENT', 'VIEWED', 'PARTIALLY_PAID', 'DRAFT' ],
	estimates: [ 'SENT', 'VIEWED' ],
	
};

export const formattedMonth = ( month ) => format( startOfMonth( month ), 'MM-dd-yyyy' );

export const formattedYear = ( year ) => format( startOfYear( year ), 'yyyy' );

export default function OpenInvoicesDialog( {
	client,
	clientStatement = false,
	clientView = false,
}: {
	client?: Client,
	clientStatement: boolean,
	clientView?: boolean
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const [ selectedTabIndex, setSelectedTabIndex ] = useState<number>( 0 );
	const [ selectedClient, setSelectedClient ] = useState<Client | null>( null );
	const [ checkedClients, setCheckedClients ] = useState<Client[]>( [] );
	const [ variables, setVariables ] = useState( null );
	const [ month, setMonth ] = useState<Date | null>( null );
	const [ year, setYear ] = useState<Date | null>( null );
	const [ isYear, setIsYear ] = useState( false );
	const [ customDates, setCustomDates ] = useState( [] );
	
	const [ currentTab, setCurrentTab ] = useState( 0 );
	
	const superAdminClientVariables = client ? { clientEmail: client.email } : null;
	const timezone = getBrowserTimezone();
	
	const [ debouncedClient ] = useDebouncedValue( selectedClient, 500 );
	const excludePaidFromStatement = staff?.company?.metadata?.excludePaidFromStatement;
	const { data: commerces, isFetching: commercesIsFetching } = useGraphQL<QueryCommercesStatementArgs>(
		{
			query    : CommercesStatement,
			queryKey : [ 'commercesStatement' ],
			variables: {
				options : { limit: 1500, filter: { timezone } },
				clientId: debouncedClient?.id || client?.id,
				excludePaidFromStatement,
				staffId : staff?.id,
			},
		}, { enabled: clientView || Boolean( debouncedClient?.id ) && clientStatement } );
	
	const commercesData = commerces?.commercesStatement?.items;
	
	const dates = useOpenStatementsDatesMemo( commercesData || [], clientStatement, selectedClient );
	
	const tabs = clientStatement || clientView ? [ 'month', 'year' ] : [ 'invoices', 'orders', 'estimates' ];
	
	const selectedTab = tabs[ selectedTabIndex ];
	const selectedStatuses = !clientStatement && selectedTab ? statuses[ selectedTab ] : undefined;
	
	useEffect( () => {
		if ( isYear ) {
			setSelectedTabIndex( 1 );
		} else {
			setSelectedTabIndex( 0 );
		}
	}, [ isYear ] );
	
	if ( clientStatement && Boolean( client ) && isEmpty( commercesData ) && !commercesIsFetching ) {
		return (
			<CompanyMetricEmptyPage
				name='payment'
				description='Your statement history is currently empty. Once you create or receive an order, it will appear here for easy tracking and management.'
			/>
		);
	}
	
	return (
		<PageWrapper
			hideBack
			modalWrapper={!clientView}
			maxWidth='xl'
			boxProps={{
				sx: {
					height  : !clientView ? '100vh' : 'unset',
					bgcolor : !clientView ? 'background.default' : 'unset',
					overflow: !clientView ? 'scroll' : 'unset',
				},
			}}
			primary={clientStatement ? t( 'management:statement' ) : `${t( 'management:open' )} ${selectedTab}`}
			icon={!clientView && (
				<IconButton onClick={closeModal}>
					<CloseIcon/>
				</IconButton>
			)}
			actions={(
				<OpenInvoicesDialogActions
					selectedTab={selectedTab}
					currentTab={currentTab}
					commercesData={commercesData}
					clientStatement={clientStatement}
					selectedClient={selectedClient}
					checkedClients={checkedClients}
					variables={variables}
					month={month || dates?.months[ 0 ]?.value}
					year={year || dates?.years[ 0 ]?.value}
					customDates={customDates}
					selectedStatuses={selectedStatuses}
					clientView={clientView}
				/>
			)}>
			<Grid container spacing={2}>
				{!clientView && (
					<Grid item xs={12} md={3}>
						<SelectClient
							statuses={statuses}
							year={year}
							month={month}
							setMonth={setMonth}
							setYear={setYear}
							setDates={setCustomDates}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
							selectedClient={selectedClient}
							setSelectedClient={setSelectedClient}
							checkedClients={checkedClients}
							setCheckedClients={setCheckedClients}
							setVariables={setVariables}
							clientStatement={clientStatement}
							isYear={isYear}
							setIsYear={setIsYear}
						/>
					</Grid>
				)}
				<Grid item xs={12} md={clientView ? 12 : 9}>
					<Paper variant='borderless' sx={{ p: 2, height: 'fit-content', position: 'sticky', top: 65 }}>
						{!checkedClients?.length && (
							<UrlFields
								clientStatement={clientStatement}
								selectedClient={selectedClient}
								variables={variables || superAdminClientVariables}
								selectedTab={selectedTab}
								selectedStatuses={selectedStatuses}
								month={month || dates?.months[ 0 ]?.value}
								year={year || dates?.years[ 0 ]?.value}
								currentTab={currentTab}
								customDates={customDates}
							/>
						)}
						<Box my={2}>
							{!clientStatement || clientView || !checkedClients?.length && !selectedClient ? (
								<ClientStatementContent
									dates={dates}
									month={month}
									year={year}
									clientView={clientView}
									variables={variables || superAdminClientVariables}
									selectedClient={selectedClient || client}
									commercesIsFetching={commercesIsFetching}
									selectedTabIndex={clientView || !clientStatement ? selectedTabIndex : isYear ? 1 : 0}
									commercesData={commercesData}
									clientStatement={clientStatement}
									setMonth={setMonth}
									setYear={setYear}
									setSelectedTabIndex={setSelectedTabIndex}
									setVariables={setVariables}
								/>
							) : checkedClients?.length <= 1 && selectedClient ? (
								<Fade in>
									<Box>
										<GraphqlTable<Order, QueryCommercesStatementArgs>
											showFooter
											searchable
											disableUrlSync
											queryKey='commerces'
											query={CommercesStatement}
											variables={{
												clientId   : selectedClient?.id,
												year       : currentTab === 0 && isYear ? year : null,
												month      : currentTab === 0 && !isYear ? month : null,
												customDates: currentTab === 1 ? customDates : null,
												options    : { filter: { timezone } },
												excludePaidFromStatement,
											}}
											columns={statementColumns( '' )}
											initialState={{ hiddenColumns: [ 'type' ] }}
											expandedComponent={( row ) => <RowPanel row={row}/>}
											mobileRenderRow={( commerce ) => <MobileRowPanel data={commerce as any}/>}
										/>
									</Box>
								</Fade>
							) : (
								<MultipleClientStatements
									timezone={timezone}
									month={month}
									year={year}
									currentTab={currentTab}
									customDates={customDates}
									checkedClients={checkedClients}
								/>
							)}
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</PageWrapper>
	);
}
