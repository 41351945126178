import { Client, ClientTotal, QueryCommercesStatementUnpaidTotalsArgs } from '@/types/schema';
import { Box, Fade } from '@mui/material';
import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { CommercesStatementUnpaidTotals } from '../../data/commerce/commerce.graphql';
import currencyFormat from '../../helpers/currencyFormat';
import GraphqlTable from '../graphqlTable';

export default function MultipleClientStatements( {
	month,
	year,
	checkedClients,
	timezone,
	currentTab,
	customDates,
}: {
	month?: Date | null,
	year?: Date | null,
	checkedClients: Client[],
	timezone: string,
	currentTab?: number,
	customDates?: Date[]
} ) {
	
	const clientIds = checkedClients.map( ( client ) => client.id );
	const baseColumns: Column<ClientTotal>[] = useMemo( () => [ {
		accessor     : 'name',
		Header       : 'Client Name',
		disableSortBy: true,
		props        : { style: { minWidth: 220 } },
		width        : 20,
	}, {
		accessor     : 'email',
		Header       : 'Email',
		disableSortBy: true,
		props        : { style: { minWidth: 220 } },
		width        : 20,
	}, {
		accessor     : ( row ) => row.totals.grandTotal,
		Header       : 'Total',
		width        : 8,
		disableSortBy: true,
		props        : { style: { minWidth: 80 } },
		Cell         : ( { value } ) => currencyFormat( value ) as any,
	}, {
		accessor     : ( row ) => row.totals.paidTotal,
		Header       : 'Paid',
		width        : 8,
		disableSortBy: true,
		props        : { style: { minWidth: 80 } },
		Cell         : ( { value } ) => currencyFormat( value ) as any,
	}, {
		accessor     : ( row ) => row.totals.balanceUnpaid,
		Header       : 'Total Balance (Unpaid)',
		width        : 15,
		disableSortBy: true,
		props        : { style: { minWidth: 80 } },
		Cell         : ( { value } ) => currencyFormat( value ) as any,
	} ], [ checkedClients ] );
	
	return (
		<Box>
			<Fade in>
				<Box>
					<GraphqlTable<ClientTotal, QueryCommercesStatementUnpaidTotalsArgs>
						query={CommercesStatementUnpaidTotals}
						queryKey='commercesStatementUnpaidTotals'
						variables={{
							options: {
								filter: {
									month                   : currentTab === 0 ? month : null,
									year                    : currentTab === 0 ? year : null,
									clientIds               : clientIds,
									customDates             : currentTab === 1 && customDates ? customDates : null,
									timezone,
									excludePaidFromStatement: true,
								},
							},
						}}
						columns={baseColumns}
					/>
				</Box>
			</Fade>
		</Box>
	);
	
}
