import GraphqlTable from '@/components/graphqlTable';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Order, QueryEstimatesReadArgs } from '@/types/schema';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { EstimatesRead } from '../../../../data/commerce/estimate.graphql';
import MobileRowPanel from '../components/mobileRow';
import useDeletedEstimateActions from './actions/deletedTableActions';
import RowPanel from './rowPanel';
import { columns } from './table';

export default function DeletedEstimatesModal() {
	const { staff } = useUserInfo();
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	
	return (
		<ResponsiveModalContainer
			title={t( 'commerce:deleted-estimates' )}
			secondaryTitle={t( 'commerce:recover-your-deleted-estimates' )}
			saveButtonText={t( 'common:done' )}
			onSave={async () => await queryClient.invalidateQueries( [ 'order' ] )}>
			<GraphqlTable<Order, QueryEstimatesReadArgs>
				disableUrlSync
				showFooter
				searchable
				queryKey='estimate'
				query={EstimatesRead}
				subscription={{ ESTIMATE: staff?.company.id ?? '' }}
				columns={() => columns( 'deletedAt' )}
				variables={{
					options: {
						filter: {
							deletedAt: { $ne: null },
						},
					},
				}}
				useActions={useDeletedEstimateActions}
				initialState={{
					hiddenColumns: [
						'id',
						'taxTotal',
						'updatedAt',
						'client.id',
						'metadata.signature',
						'po',
						'due',
						'type',
					],
				}}
				expandedComponent={( row ) => <RowPanel row={row}/>}
				mobileRenderRow={( estimate ) => <MobileRowPanel data={estimate as any}/>}
			/>
		</ResponsiveModalContainer>
	);
}
