import type { BoxProps, TabsProps } from '@mui/material';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import type { CSSProperties, ReactNode } from 'react';
import { Fragment, useEffect, useState } from 'react';
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import useControlledState from '../hooks/useControlledState';

const EnhancedSwipeableViews = virtualize( SwipeableViews ) as SwipeableViewsProps;

export const MuiTabsCustomStyles = {
	'mb'                : 1,
	'py'                : 1,
	// Tab Custom Styling
	'.MuiTabs-indicator': { height: 30, borderRadius: 1 },
	'.MuiTabs-root'     : {
		'height'                             : 30,
		'minHeight'                          : 30,
		'overflow'                           : 'visible',
		'.Mui-selected, &.Mui-selected:hover': { color: 'white', zIndex: 1 },
		'.MuiTab-root'                       : {
			'padding'            : 0,
			'height'             : 30,
			'minHeight'          : 30,
			'borderRadius'       : 1,
			'transition'         : 'color .2s',
			'textTransform'      : 'capitalize',
			'.MuiButtonBase-root': {
				minWidth    : 'auto',
				paddingLeft : 20,
				paddingRight: 20,
				marginRight : 4,
			},
			':hover': { color: 'text.primary' },
		},
	},
};

export const SegmentControlStyles = {
	'bgcolor'           : 'colors.tabs.segmentControlBg',
	'display'           : 'inline-block',
	'borderRadius'      : 2,
	'p'                 : 0,
	'mb'                : 1,
	// Tab Custom Styling
	'.MuiTabs-indicator': { height: 28, borderRadius: 2, bgcolor: 'colors.tabs.segmentControlCard' },
	'.MuiTabs-root'     : {
		'p'                                  : .4,
		'minHeight'                          : 28,
		'overflow'                           : 'visible',
		'.Mui-selected, &.Mui-selected:hover': { color: 'black', zIndex: 1 },
		'.MuiTouchRipple-root'               : { display: 'none' },
		'.MuiTab-root'                       : {
			'color'        : 'colors.tabs.segmentControlColor',
			'padding'      : 0,
			'minHeight'    : 28,
			'borderRadius' : 2,
			'minWidth'     : 100,
			'px'           : 1,
			'transition'   : 'color .2s',
			'textTransform': 'capitalize',
			'.Mui-selected': { boxShadow: '0 1px 3px rgba(0,0,0,0.050),0 1px 2px rgba(0,0,0,0.05)' },
		},
		'.MuiTabs-scrollButtons.Mui-disabled': {
			opacity: 0.3,
		},
	},
};

export default function SwipeableTabViews( {
	tab = 0,
	setTab,
	renderTabs,
	renderContent,
	containerProps,
	tabsWrapperProps,
	rightnode,
	...props
}: {
	tab?: number,
	setTab?: ( index: number ) => void,
	renderTabs: ReactNode[],
	renderContent: ( index: number ) => ReactNode,
	containerProps?: BoxProps,
	tabsWrapperProps?: BoxProps,
	rightnode?: ( index: number ) => ReactNode
} & TabsProps ) {
	const theme = useTheme();
	const [ tabValue, setTabValue ] = useControlledState( tab, setTab );
	const [ swipeFix, setSwipeFix ] = useState<CSSProperties>();
	
	useEffect( () => {
		setTimeout( () => setSwipeFix( {
			transition: theme.transitions.create( 'transform' ),
		} ), 500 );
	}, [] );
	
	return (
		<Box {...containerProps}>
			<Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Box {...tabsWrapperProps}>
					<Tabs
						selectionFollowsFocus
						value={tabValue}
						onChange={( e, index ) => setTabValue( +index )}
						{...props}>
						{renderTabs.map( ( label, index ) => <Tab key={index} label={label}/> )}
					</Tabs>
				</Box>
				{rightnode && rightnode( tabValue )}
			</Box>
			<EnhancedSwipeableViews
				index={tabValue}
				slideCount={renderTabs.length}
				slideRenderer={( { index } ) => <Fragment key={index}>{renderContent( index )}</Fragment>}
				containerStyle={swipeFix}
				slideStyle={{ overflowX: 'hidden' }}
				onChangeIndex={( index ) => setTabValue( index )}
			/>
		</Box>
	);
}
