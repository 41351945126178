import { Client, Order } from '@/types/schema';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

interface DateLabelPair {
	value: Date | string,
	label: string
}

interface DatesAccumulator {
	months: DateLabelPair[],
	years: DateLabelPair[]
}

export default function useOpenStatementsDatesMemo(
	commercesData: Order[],
	clientStatement: boolean,
	selectedClient?: Client | null,
) {
	return useMemo( () => {
		if ( clientStatement && !isEmpty( commercesData ) ) {
			return commercesData?.reduce<DatesAccumulator>( ( dates, commerce ) => {
				const newMonth = format( new Date( commerce.standingDate || commerce.createdAt ), 'MMMM yyyy' );
				const newYear = format( new Date( commerce.standingDate || commerce.createdAt ), 'yyyy' );
				if ( !dates.months.find( ( month ) => month.label === newMonth ) ) {
					dates = {
						...dates,
						months: [
							...dates.months,
							{ value: commerce.standingDate || commerce.createdAt, label: newMonth } ],
					};
				}
				if ( !dates.years.find( ( month ) => month.label === newYear ) ) {
					dates = {
						...dates,
						years: [ ...dates.years, { value: commerce.standingDate || commerce.createdAt, label: newYear } ],
					};
				}
				return dates;
			},
			{ months: [], years: [] },
			) as {
				months: { value: Date, label: string }[],
				years: { value: Date, label: string }[]
			};
		}
	}, [ selectedClient, commercesData ] );
}

