import Attachment from '@/components/fileUploading/attachment';
import GraphqlTable from '@/components/graphqlTable';
import OverflowTypography from '@/components/overflowTypography';
import StyledImage from '@/components/styledImage';
import UserPopover from '@/components/userPopover';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { Avatar, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty, startCase, toLower } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import { EstimatesRead } from '../../../../data/commerce/estimate.graphql';
import currencyFormat from '../../../../helpers/currencyFormat';
import type { Order, QueryEstimatesReadArgs } from '../../../../types/schema';
import CommerceStatusChip from '../chips/commerceStatusChip';
import MobileRowPanel from '../components/mobileRow';
import {
	CalculateClients,
	CalculateDate,
	CalculateNumber,
	CalculateTotal,
	CalculateUnique,
} from '../components/tableHelpers';
import CommerceTablePreviewDrawer from '../drawers/commerceTablePreviewDrawer';
import EstimatesActions from './actions/multiActions';
import useEstimatesQuickFilterActions from './actions/quickFilterActions';
import useEstimateActions from './actions/tableActions';
import DeletedEstimatesModal from './deletedEstimates';
import RowPanel from './rowPanel';

export const columns: ( args ) => Array<Column<any> | null> = ( client ) => [ {
	accessor      : 'id',
	Header        : 'common:id',
	disableSortBy : true,
	disableFilters: true,
	width         : 20,
	props         : { style: { minWidth: 50 } },
}, {
	accessor   : ( row ) => row.metadata?.customNumber || row.number,
	Header     : 'commerce:estimate',
	filterByKey: 'number',
	props      : { style: { minWidth: 135 } },
	width      : 12,
	Cell       : ( { value, row } ) => {
		const { showModal } = useModal();
		return (
			<Tooltip title='Preview'>
				<Stack
					sx={{ cursor: 'pointer' }}
					direction='row'
					spacing={1}
					alignItems='center'
					justifyContent='space-between'
					onClick={( e ) => {
						e.stopPropagation();
						showModal( CommerceTablePreviewDrawer, {
							variant: 'drawer',
							sx     : row.original.deletedAt
								? { zIndex: 1400 }
								: undefined,
						}, {
							dataId: row.original.id,
							type  : 'ESTIMATE',
						} );
					}}>
					<Typography sx={{ textDecoration: 'underline' }}>{value}</Typography>
					{!isEmpty( row.original?.attachments ) && (
						<Tooltip title='Includes Attachments'>
							<AttachFileIcon sx={{ fontSize: 12, color: 'text.secondary' }}/>
						</Tooltip>
					)}
					{row.original?.externalValue && client !== 'client' && (
						<Tooltip title='Available on Quickbooks'>
							<StyledImage
								alt='qb-icon'
								src='/images/qb-icon.png'
								width='12px'
								height='12px'
							/>
						</Tooltip>
					)}
				</Stack>
			</Tooltip>
		);
	},
	Footer: ( { rows } ) => CalculateNumber( rows, 'Estimate' ),
}, {
	accessor       : ( row ) => row.client?.name || row.client?.contact,
	Header         : client ? 'Contact' : 'common:client',
	filterByKey    : 'client.contact',
	multipleFilters: [ 'name', 'contact' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
	Footer: ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, client === 'client' ? {
	accessor      : 'company' as any,
	Header        : 'common:company',
	disableFilters: true,
	width         : 20,
	props         : { style: { minWidth: 168 } },
	Cell          : ( { value } ) => value?.name || value?.contact || '-',
	Footer        : ( { rows } ) => CalculateClients( rows, [
		'company.name',
		'company.contact' ], 'Company' ),
} : null, {
	accessor     : 'type',
	Header       : 'common:type',
	filterOptions: [ 'ESTIMATE', 'QUOTE', 'WORK_ORDER', 'BID', 'PROPOSAL' ],
	Cell         : ( { value } ) => value ? startCase( toLower( value ) ) : '-' as any,
	props        : { style: { minWidth: 135 } },
	width        : 12,
}, {
	accessor   : 'client.id' as any,
	Header     : 'common:client-id',
	sortType   : 'select',
	filterByKey: 'client.id',
	width      : 20,
	props      : { style: { minWidth: 168 } },
	Cell       : ( { value } ) => value || '-',
	Footer     : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, {
	accessor       : 'status',
	Header         : 'common:status',
	filterByKey    : 'status',
	multipleFilters: [ 'SENT', 'VIEWED' ],
	filterOptions  : [ ...client ? [] : [ 'DRAFT' ], ...[
		'SENT',
		'CANCELLED',
		'REFUNDED',
		'MERGED',
		'OPEN',
		'STANDING',
		'COMPLETED',
		'VIEWED',
		'INVOICED',
		'ORDERED',
		'DECLINED',
	] ],
	width : 10,
	props : { style: { minWidth: 80 } },
	Cell  : ( data ) => <CommerceStatusChip cellData={data}/>,
	Footer: ( { rows } ) => CalculateUnique( rows, 'status' ),
}, !client ? {
	accessor       : ( row ) => row.companyLocation?.name || row.companyLocation?.address?.line1,
	Header         : 'management:location',
	filterByKey    : 'companyLocation.name',
	multipleFilters: [ 'name', 'address.line1' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
} : null, {
	accessor      : 'po',
	Header        : 'common:po',
	disableFilters: true,
	Cell          : ( { value } ) => value || '',
	props         : { style: { minWidth: 135 } },
	width         : 12,
}, {
	accessor: 'serviceDate',
	Header  : 'common:service-date',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Cell    : ( { value } ) => value && format( value, 'PPp' ) as any,
	Footer  : ( { rows } ) => CalculateDate( rows, 'serviceDate' ),
}, client === 'deletedAt' ? {
	accessor: 'deletedAt',
	Header  : 'common:deletedAt',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'deletedAt' ),
} : null, !client ? {
	accessor   : 'staff' as any,
	Header     : 'common:placed-by',
	sortType   : 'select',
	filterByKey: 'staff.user.firstName',
	width      : 10,
	props      : { style: { minWidth: 110 } },
	Cell       : ( { value } ) => (
		<UserPopover user={value?.user}>
			<Chip
				label={value?.user.firstName}
				size='small'
				avatar={(
					<Avatar
						alt={value?.user.firstName}
						src={value?.user?.image}
						sx={{
							border     : 1.5,
							borderColor: 'colors.opposite',
						}}
					/>
				)}
			/>
		</UserPopover>
	),
} : null, {
	accessor      : 'metadata.signature' as any,
	Header        : 'common:signature',
	disableSortBy : true,
	disableFilters: true,
	props         : { style: { minWidth: 90 } },
	width         : 12,
	Cell          : ( { value } ) => value ? (
		<Attachment
			removeDownload
			src={value}
			imageSX={{ width: 40, height: 40, objectFit: 'cover', mb: 0 }}
		/>
	) : null,
}, {
	accessor: 'updatedAt',
	Header  : 'common:updated',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'updatedAt' ),
}, {
	accessor: 'taxName',
	Header  : 'Tax',
	width   : 20,
	props   : { style: { minWidth: 168 } },
}, {
	accessor: 'taxTotal',
	Header  : 'common:tax',
	sortType: 'number',
	width   : 20,
	props   : { style: { minWidth: 138 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'taxTotal', true ),
}, {
	accessor: 'grandTotal',
	Header  : 'common:total',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 90 } },
	Cell    : ( { value } ) => currencyFormat( Math.abs( +value ) ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'grandTotal', true ),
} ].filter( Boolean );

export default function EstimateTable() {
	const { staff } = useUserInfo();
	const { showModal } = useModal();
	const { t } = useTranslation();
	return (
		<GraphqlTable<Order, QueryEstimatesReadArgs>
			showFooter
			searchable
			queryKey='estimates'
			query={EstimatesRead}
			columns={columns}
			hiddenTableColumns='estimatesHiddenColumns'
			tableActionMenu={[ t( 'common:export' ), 'estimatesPdf', 'estimatesCSV' ]}
			rowRoute={( { id } ) => `/dashboard/commerce/estimates/${id}`}
			subscription={{ ESTIMATE: staff?.company.id ?? '' }}
			initialState={{
				hiddenColumns: staff?.metadata?.estimatesHiddenColumns
					? staff?.metadata?.estimatesHiddenColumns
					: [
						'id',
						'taxTotal',
						'updatedAt',
						'client.id',
						'taxName',
						'metadata.signature',
						'po',
						'type',
						'management:location',
					],
			}}
			expandedComponent={( row ) => <RowPanel row={row}/>}
			useActions={useEstimateActions}
			useQuickFilters={useEstimatesQuickFilterActions as any}
			renderDeletedData={() => showModal( DeletedEstimatesModal, { maxWidth: 'xl' } )}
			mobileRenderRow={( estimate ) => <MobileRowPanel data={estimate as any}/>}
			renderMultiActions={( estimates, clearRows, selectedRows ) => (
				<EstimatesActions
					estimates={estimates}
					clearRows={clearRows}
					selectedEstimates={selectedRows}
				/>
			)}
		/>
	);
}
